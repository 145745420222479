<template>
  <div class="row">
    <div class="col-sm-3">
      <RWCard title="Overview" :bodyFit="true">
        <template #body>
          <b-list-group flush>
            <b-list-group-item class="text-truncate"
              >Information</b-list-group-item
            ></b-list-group
          >
        </template>
      </RWCard>
      <RWCard title="Actions" :bodyFit="true">
        <template #body>
          <b-list-group flush>
            <b-list-group-item class="text-truncate"
              >Request Cancellation</b-list-group-item
            ></b-list-group
          >
        </template>
      </RWCard>
    </div>
    <div class="col-sm-9">
      <!--  banner    -->
      <RWCard :title="status.name + ' - ' + status.domain" :bodyFit="true">
        <template #body>
          <div class="well" id="banner">
            <p style="margin-top: 260px" class="text-center"></p>
          </div>
        </template>
      </RWCard>

      <div class="row">
        <!--informasi account-->
        <div class="col-sm-6">
          <RWCard title="Informasi Account" :bodyFit="true">
            <template #body>
              <template v-if="loading.account">
                <v-skeleton-loader
                  type="text@3"
                  class="m-2"
                  :tile="true"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <div class="alert alert-danger m-3" v-if="!success.account">
                  {{ xhr.account }}
                </div>
                <table class="table table-hover table-striped" v-else>
                  <tbody>
                    <tr v-if="status.module === 'rwvpsalibaba'">
                      <td>URL Login</td>
                      <td>:</td>
                      <td>
                        <a
                          href="https://signin.alibabacloud.com/login.htm?callback=https://swas.console.aliyun.com#/main"
                          target="_blank"
                          >Klik di sini</a
                        >
                      </td>
                    </tr>
                    <tr v-else>
                      <td>Hostname</td>
                      <td>:</td>
                      <td>
                        {{ xhr.account.domain }}
                      </td>
                    </tr>
                    <tr>
                      <td>Username</td>
                      <td>:</td>
                      <td style="word-break: break-all">
                        {{ xhr.account.post.username }}
                      </td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      <td>:</td>
                      <td>
                        {{ password.value }}
                        <span
                          class="rw-cursor-pointer"
                          v-b-tooltip.hover
                          :title="
                            password.sensor
                              ? 'Lihat Password'
                              : 'Sembunyikan Password'
                          "
                          @click="togglePwd"
                        >
                          <i
                            class="fa fa-eye mx-2"
                            :class="
                              password.sensor ? 'text-success' : 'text-danger'
                            "
                          ></i>
                        </span>
                        <span
                          class="rw-cursor-pointer"
                          v-b-tooltip.hover
                          title="Copy Password"
                          @click="copyPwd"
                        >
                          <i class="fa fa-clone mx-2 text-warning"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </RWCard>
        </div>

        <!--product detail-->
        <div class="col-sm-6">
          <RWCard title="Product Detail" :body-fit="true">
            <template #body>
              <table class="table table-hover table-striped">
                <tbody>
                  <tr>
                    <td>Product / Service</td>
                    <td>:</td>
                    <td>{{ status.name }}</td>
                  </tr>
                  <tr>
                    <td>Registration date</td>
                    <td>:</td>
                    <td>{{ status.regdate }}</td>
                  </tr>
                  <tr>
                    <td>Billing Cycle</td>
                    <td>:</td>
                    <td>{{ status.recurring }}</td>
                  </tr>
                  <tr>
                    <td>Next Due Date</td>
                    <td>:</td>
                    <td>{{ status.duedate }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </RWCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { ApiURL } from "@/common/mixins/general";
import copy from "clipboard-copy";
import { ADD_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "vps-single-active",
  components: { RWCard },
  mixins: [ApiURL],
  props: {
    status: {
      required: true
    }
  },
  data() {
    return {
      loading: {
        account: true
      },
      xhr: {
        account: {}
      },
      success: {
        account: false
      },
      password: {
        sensor: true,
        value: "****"
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * get account vps like a username, password etc
     * with param id
     * --------------------------------------------------
     */
    getAccount: function() {
      this.http
        .get(this.end.vps.account, this.status.id)
        .then(({ data }) => {
          this.xhr.account = data.data.account;
          this.success.account = true;

          this.password.value = this.sensorPwd();
          console.warn("x", data);
        })
        .catch(error => {
          this.xhr.account = error.message;
          console.warn("xx", error.message);
        })
        .finally(() => {
          this.loading.account = false;
          console.warn("x selesai");
        });
    },

    /**
     * --------------------------------------------------
     * sensor password
     * --------------------------------------------------
     */
    sensorPwd: function() {
      let pwd = this.xhr.account.post.password,
        length = pwd.length,
        pass = "";
      for (let i = 1; i <= length + 3; i++) {
        pass += "*";
      }
      return pass;
    },

    /**
     * --------------------------------------------------
     * toggle show or hide password
     * --------------------------------------------------
     */
    togglePwd: function() {
      if (this.password.sensor) {
        this.password.value = this.xhr.account.post.password;
      } else {
        this.password.value = this.sensorPwd();
      }

      this.password.sensor = !this.password.sensor;
    },

    /**
     * --------------------------------------------------
     * copy password
     * --------------------------------------------------
     */
    copyPwd() {
      copy(this.xhr.account.post.password);
      this.$bvToast.toast("Password has been copied", {
        title: "Success",
        solid: true,
        variant: "success",
        autoHideDelay: 4000
      });
    }
  },
  mounted() {
    this.getAccount();
    this.$store.dispatch(ADD_BREADCRUMB, [{ title: this.status.domain }]);
  }
};
</script>

<style scoped>
#banner {
  background-image: url("/assets/rw/rwpanel-banner.jpg");
  background-color: #33aae2;
  background-repeat: no-repeat;
  background-position: 50% 0;
}
</style>
