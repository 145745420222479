<template>
  <div>
    <div class="row" v-if="loading">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <v-skeleton-loader type="chip"></v-skeleton-loader> <br />
            <v-skeleton-loader type="text@3"></v-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="col-sm-9">
        <div class="card">
          <div class="card-body">
            <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
            <v-skeleton-loader type="sentences@3"></v-skeleton-loader>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
                <v-skeleton-loader type="sentences"></v-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
                <v-skeleton-loader type="sentences"></v-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <template v-if="is_success">
        <component :status="status" :is="view"></component>
      </template>
      <template v-else>
        <div class="alert alert-danger">{{ status }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import vpsActive from "@/views/pages/product/vps/vps-single-active";
import vpsNonActive from "@/views/pages/product/slot/slotLayananNonActive.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { ApiURL } from "@/common/mixins/general";

export default {
  name: "vps-single",
  mixins: [ApiURL],
  components: {
    vpsActive,
    vpsNonActive
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      status: {},
      is_success: false
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * request status vps aktif / tidak
     * yang selanjutnya akan diarahkan ke page aktif / tidak
     * --------------------------------------------------
     */
    getStatusVps: function() {
      this.http
        .get(this.end.vps.status, this.id)
        .then(({ data }) => {
          this.status = data.data.status;
          this.is_success = true;
        })
        .catch(error => {
          this.status = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    view: function() {
      return this.status.status === "Active" ? "vpsActive" : "vpsNonActive";
    }
  },
  mounted() {
    this.getStatusVps();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "VPS", route: "product/vps" }
    ]);
  }
};
</script>
